import React from 'react';

const experienceContent = [
   {
    year: '2022(October) - Present',
    position: 'Senior Developer',
    compnayName: 'Cloudfm Group',
    website: 'https://cloudfmgroup.com',

    details: `Spearheading the re-development of a new mobile app using React Native, Typescript and GraphQL`,
  },
  {
    year: '2022 - May',
    position: 'Front-End Developer',
    compnayName: 'Sciwheel',
    website: 'https://sciwheel.com',

    details: `Working on a reference manager solution for web and mobile app using ReactJs and React Native`,
  },
  {
    year: '2022 - April',
    position: 'Mobile App Developer',
    compnayName: 'Baanx',
    website: 'https://baanx.com/',

    details: `Developing and refactoring mobile applications using React Native`,
  },
  {
    year: '2022 - March',
    position: ' Machine Learning Engineer(Contract)',
    compnayName: 'Xernos',
    website: 'https://xernos.com',

    details: ` Implementing, deploying and maintaining machine learning models at scale using Python, tensorFlow, SKlearn & Pytorch`,
  },
  {
    year: '2021',
    position: 'Full Stack Developer(Contract)',
    compnayName: 'Trcoms Limited',
    website: 'https://trcomsltd.com',
    details: `Developed full-stack web and mobile video conferencing and chat application called Trivoh`,
  },
  {
    year: '2014 - 2020',
    position: 'Senior Software Engineer',
    compnayName: 'Cybermate Technologies Limited',
    website: 'https://cybermatetechnologies.com',
    details: `Extracting, synthesizing and interpreting large volumes of data from a variety of company sources using Python and leading team of developers`,
  },
  {
    year: '2011 - 2013',
    position: 'General Manager',
    compnayName: 'Emmakulate Limited',
    website: 'https://emmakulate.com',
    details: `Managed 50-member cross-functional (product, IT, sales, support) team
    and coordinated a series of government-related contracts. Spearhead the development of solutions in accordance to clients requirements`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">
            <a href={val.website} target="_blank" rel="noreferrer noopener">
              <i>{val.website}</i>
            </a>
          </p>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
