import React from "react";

const ModalTwoBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Blog <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O.
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 01 February 2022
        </span>
        <span>
          <i className="fa fa-tags"></i> Python, NLP, AI,
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>Speech Recognition Using Python</h1>
      <img src="img/blog/speech.jpg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        In this article, we'll create a programme that can comprehend what we're saying and converts it into written words. This is referred to as speech or voice recognition. Speech Recognition is a fascinating and enjoyable subject to begin your Machine Learning and Artificial Intelligence journey.
        </p>
      
        <p>
        We will take advantage of AssemblyAI's free real-time transcribing API in this project.
Online lectures, for example, are one area where real-time transcribing might be beneficial. We may read the instructor's remarks as a subtitle while listening to him. This tool will make learning simpler for persons who have hearing impairments. This is just one simple example; I'm sure there are many other applications for real-time transcribing.
        </p>
        <p>
        In this project, we'll be utilising three Python librarieswhich are: Websockets, PortAudio, and PyAudio.
PortAudio is an open-source audio input/output library that is free and cross-platform. It is also required for PyAudio to function.

PyAudio is a free and open-source library that may be used to play and record audio files on a variety of systems. This library functions as a PortAudio extension.

Websockets is a dependency that is built on top of Python's standard asynchronous I/O framework, asyncio. It has a beautiful coroutine-based API.
        </p>
        <p>
        To install PortAudio, we must utilise brew, a Python library manager. 
        `brew install portaudio`

The other two libraries can now be installed. This time, we're going to use pip, a Python library manager. Do you know that we can install one or more libraries in a single line?
`pip pyaudio websockets must be installed`
        </p>
        <p>
       Now that our libraries are installed. Time to get the streaming system ready!

        </p>
        <pre>
        import pyaudio
 
 FRAMES_PER_BUFFER = 3200
 FORMAT = pyaudio.paInt16
 CHANNELS = 1
 RATE = 16000
 p = pyaudio.PyAudio()
  
 
 stream = p.open(
    format=FORMAT,
    channels=CHANNELS,
    rate=RATE,
    input=True,
    frames_per_buffer=FRAMES_PER_BUFFER
 )
        </pre>
        <p>The parameters of our stream system are defined in the code above. When it comes to specifics, the audio world is quite sophisticated, so I recommend doing some research on each of the factors to better comprehend them.</p>
        <p>In the next step, we will write an async function to send and receive audio data with the API.</p>
        <pre>
        import websockets
        import asyncio
        import base64
        import json
        from configure import auth_key

        # the AssemblyAI endpoint we're going to hit
        URL = "wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000"
 
        async def send_receive_data():
          print(f'Connecting websocket to url ${URL}')
          async with websockets.connect(
              URL,
              extra_headers=(("Authorization", "our API key"),),
              ping_interval=5,
              ping_timeout=20
          ) as _ws:
              await asyncio.sleep(0.1)
              print("Receiving SessionBegins ...")
              session_begins = await _ws.recv()
              print(session_begins)
              print("Sending messages ...")
              async def send():
                  while True:
                      try:
                          data = stream.read(FRAMES_PER_BUFFER)
                          data = base64.b64encode(data).decode("utf-8")
                          json_data = json.dumps({"audio_data:str(data)&rbrace;)"}
                          await _ws.send(json_data)
                      except websockets.exceptions.ConnectionClosedError as e:
                          print(e)
                          assert e.code == 4008
                          break
                      except Exception as e:
                          assert False, "Not a websocket 4008 error"
                      await asyncio.sleep(0.01)
                  
                  return True
              
              async def receive():
                  while True:
                      try:
                          result_str = await _ws.recv()
                          print(json.loads(result_str)['text'])
                      except websockets.exceptions.ConnectionClosedError as e:
                          print(e)
                          assert e.code == 4008
                          break
                      except Exception as e:
                          assert False, "Not a websocket 4008 error"
              
              send_result, receive_result = await asyncio.gather(send(), receive())
        </pre>
        <p>
        The send_receive_data() function is all set!
Now, let’s put it in a loop so that our program listens continuously to our voice. Since we don’t want it to run forever, there is a timeout, which is 1 minute. Asyncio will do this for us.
asyncio.run(send_receive_data())
        </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalTwoBlogContent;
