import React from "react";

const ModalThreeBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Blog <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O.
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 9 February 2022
        </span>
        <span>
          <i className="fa fa-tags"></i> Continous Integration, Continuous Development, Jerkins, GitHub Action
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>CI/CD Demystified!</h1>
      <img src="img/blog/cicd.jpg" className="img-fluid" alt="CICD" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        Today's software delivery is much more than just putting deliverables into the hands of consumers. To be successful, teams and organisations must provide value in a timely, sustainable, and repeatable manner. CI/CD is a way of delivering apps to clients on a regular basis by incorporating automation into the phases of app development. Continuous integration, continuous delivery, and continuous deployment are the three key principles associated with CI/CD. CI/CD is a solution to the issues that new code integration might bring to development and operations teams (AKA "integration hell"). Specifically, CI/CD adds continuous automation and monitoring across the software lifecycle, from integration and testing through delivery and deployment. These interconnected processes are typically referred to as a "CI/CD pipeline," and they are supported by agile development and operations teams using either a DevOps or a site reliability engineering (SRE) strategy.
         
        </p>
        
        <p>
        Managing feature development is the most difficult task in any development team. Working with code entails developing, resolving disputes, managing code, and testing, all of which contribute to long deployment cycle times, infrequent deployments, and high change failure rates. The idea is to use CI to automate the integration of development changes, hence optimising the pace and process of feature development. The objective of contemporary application development is to have numerous developers working on various parts of the same app at the same time. However, if an organisation is configured to merge all branching source code together on a single day (known as "merge day"), the subsequent process can be arduous, manual, and time-consuming. That's because when a developer working in isolation makes a change to an application, there's a potential it will conflict with other developers making changes at the same time. This issue is exacerbated if each developer has customised their own local integrated development environment (IDE), rather than the team deciding on a single cloud-based IDE.
        </p>
        <p>
        Continuous integration (CI) allows developers to integrate code changes back to a shared branch, or "trunk," more frequently—even daily in certain cases. Once a developer's changes to an application are merged, those changes are validated by automatically creating the app and executing various levels of automated testing, often unit and integration tests, to confirm the changes did not break the programme. This includes testing everything from classes and functions to the many modules that make up the complete programme. If automated testing uncovers a conflict between new and old code, continuous integration (CI) makes it easier to address those errors fast and often.
        </p>
       
          <img src="img/blog/ci2.png" className="img-fluid" alt="Blog" />
          <p>
          Continuous delivery automates the deployment of verified code to a repository after the automation of builds and unit and integration testing in CI. So, in order to have an efficient continuous delivery approach, CI must be embedded into your development pipeline from the start. The purpose of continuous delivery is to always have a codebase ready for deployment to a production environment.

Every level of continuous delivery, from merging code changes to delivering production-ready builds, incorporates test automation and code release automation. At the end of the procedure, the operations team is able to swiftly and efficiently deploy an app to production.



          </p>
          <p>
          Continuous deployment is the final stage of a sophisticated CI/CD workflow. Continuous deployment is an extension of continuous delivery, which automates the release of a production-ready build to a code repository. It also automates the release of an app to production. Continuous deployment is largely reliant on well-designed test automation because there is no manual gate at the level of the pipeline before production.
In reality, continuous deployment implies that a developer's contribution to a cloud application might become online minutes after it is written (assuming it passes automated testing). This makes it much easier to acquire and implement customer input on an ongoing basis. Taken collectively, all of these linked CI/CD approaches make application deployment less hazardous, making it simpler to deliver updates to apps in little portions rather than all at once. However, there will be a significant upfront investment required since automated tests would need to be built to handle a range of testing and release phases in the CI/CD pipeline.
          </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalThreeBlogContent;
