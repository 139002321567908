import React from "react";
import Slider from "react-slick";

const ModalFourContent = () => {

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };

  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Plasmate App</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Project </span>:{" "}
              <span className="ft-wt-600 uppercase">Mobile App with more than 20 pages</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Client </span>:{" "}
              <span className="ft-wt-600 uppercase">Plasmate</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Languages</span>:{" "}
              <span className="ft-wt-600 uppercase">Flutter & Dart, Flask API, Firestore, ReactJs</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Preview </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://apps.apple.com/us/app/plasmate/id1578793483"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apple App Store
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <Slider {...settings}>
          <div className="slide_item">
            <img src="img/projects/plas1.png" alt="slide 1" />
          </div>
          <div className="slide_item">
            <img src="img/projects/plas2.png" alt="slide 2" />
          </div>
          <div className="slide_item">
            <img src="img/projects/plas3.png" alt="slide 2" />
          </div>
          <div className="slide_item">
            <img src="img/projects/plas4.png" alt="slide 2" />
          </div>
        </Slider>
        {/* <div className="videocontainer">
          <video
            id="video"
            className="responsive-video"
            controls
            poster="img/projects/project-1.jpg"
          >
            <source src="img/projects/video.mp4" type="video/mp4" />
          </video>
        </div> */}
        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalFourContent;
