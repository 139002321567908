import React from "react";

const ModalFourBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Blog <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O.
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 19 January 2022
        </span>
        <span>
          <i className="fa fa-tags"></i> Agile, Scrum, Sprint, Lean, XP
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>Is Agile Really Worth the Investment?</h1>
      <img src="img/blog/agile.jpg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        Some aficionados believe agile is the secret to all future success—but is it all it's made up to be? Is agile worth the time, manpower, material, energy, and other resources it necessitates in the end?
            
        </p>
        
        <p>
        To begin, it is critical to understand that agile, which is based on the Agile Manifesto released in 2001, incorporates fundamental concepts. There are over 40 groups, businesses, and approaches that claim to be the best at implementing agile. But, if they're worth their salt, they'll incorporate one or more of the following principles:
<ul>
<li>Prioritize people over processes.</li>
<li>In order to consistently improve, include clients and their feedback.
Deconstruct work into small pieces and arrange effort into short chunks (usually referred to as sprints) to receive immediate feedback and make nimble (agile!) course changes.</li>
<li>Assign workers to teams and concentrate on one project at a time.</li>
<li>Experiment and learn all the time.</li>
<li>Ensure that the work is transparent and that the team remains cohesive.</li>
</ul>
        </p>
        <p>
        This new style of working is quite a lot of effort. Agile should, ideally, not add to the strain of a team. It should be a new way of performing the same thing. It is expected to boost efficiency and throughput (velocity). However, any change involves considerable effort—roles, organisational processes, leadership techniques, and even incentives and recognition must all be altered. Finally, deploying agile is a strategic choice that necessitates substantial commitment.
        </p>
        <p>
        The reasons agile works are maybe as fascinating as the outcomes they provide. A research presented at an information systems conference in New Zealand discovered that when agile teams function efficiently, they tend to exchange a lot of information. This helps to foster common mental models and helpful actions. Essentially, rapid information flow (information density) benefits persons who have common methods of thinking and comprehending. This shared experience adds to team members being aware of one other's needs and providing support, aid, and direction on tasks, resulting in improved overall outcomes.
        </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalFourBlogContent;
