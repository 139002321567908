import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>260, Wood Street, Waltham Forest, London E17 3NG.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:netappzone@gmail.com">netappzone@gmail.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href="Tel: +44 7754 327 291">+44 7754 327 291</a>
      </p>
      {/* End .custom-span-contact */}
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-linkedin position-absolute"></i>
        <span className="d-block">Linkedin</span>{" "}
        <a href="https://www.linkedin.com/in/johnsonoy/" target="_blank" rel="noreferrer noopener">"https://www.linkedin.com/in/johnsonoy/"</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
