import React from 'react';

const certificationsContent = [
   {
    position: 'Microsoft Certified: DevOps Engineer Expert',
    compnayName: 'Issued by: Microsoft',
    details: `https://learn.microsoft.com/en-us/users/johnsonoyeniyi-5447/credentials/b89f4635a9a9bb3`,
  },
  {
    position: 'TensorFlow Developer Certificate',
    compnayName: 'Issued by: TensorFlow',
    details: `https://www.credential.net/0361ce5a-3162-488b-8d41-336c5cf3efad`,
  },
  {
    // year: "   2018 - Present",
    position: 'AWS Certified Solutions Architect – Associate',
    compnayName: 'Issued by: Amazon',
    details: `https://www.credly.com/badges/bfe9326c-b5aa-4942-9753-7debfcff4f05?source=linked_in_profile`,
  },
  {
    // year: "2013 - 2018",
    position: 'Microsoft Certified: Azure Data Scientist Associate',
    compnayName: 'Issued by: Microsoft',
    details: `https://learn.microsoft.com/en-us/users/johnsonoyeniyi-5447/credentials/47b6fe17f1ee7a15`,
  },
  {
    // year: "2013 - 2018",
    position: 'Microsoft Certified: Azure Developer Associate',
    compnayName: 'Issued by: Microsoft',
    details: `https://learn.microsoft.com/en-us/users/johnsonoyeniyi-5447/credentials/b03aff02f739e0cb`,
  },
  {
    // year: "2005 - 2013",
    position: 'Microsoft Certified: Azure AI Fundamentals',
    compnayName: 'Issued by: Microsoft',
    details: `https://www.credly.com/badges/71b24c58-808c-4271-acfb-1093625bd676/public_url`,
  },
  {
    position: 'DeepLearning.AI TensorFlow Developer Specialization',
    compnayName: 'Issued by: Coursera',
    details: `https://www.coursera.org/account/accomplishments/specialization/certificate/2ZX4B82ZG5JK`,
  },
  {
    position: 'Architecting with Google Cloud Platform',
    compnayName: 'Issued by: Coursera',
    details: `https://www.coursera.org/account/accomplishments/specialization/C539ZAM3V6DG`,
  },
  {
    position: 'Agile Scrum Master(ASM®)',
    compnayName: 'Issued by: Simplilearn',
    details: `https://simpli.app.link/nez18R4bApb`,
  },
  {
    // year: "2005 - 2013",
    position: 'Microsoft Certified: Azure Fundamentals',
    compnayName: 'Issued by: Microsoft',
    details: `https://www.credly.com/badges/1967962f-719c-4f49-82d3-cc886efd4807?source=linked_in_profile`,
  },
];

const Certifications = () => {
  return (
    <ul>
      {certificationsContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">
            <a href={val.details} target="_blank" rel="noreferrer noopener">
              <i>{val.details}</i>
            </a>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default Certifications;
