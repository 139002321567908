import React from "react";

const ModalFiveBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 9 September 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> medicine, telemedicine, cancer, chemotherapy
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>Telemedicine and its impact on breast cancer survival in Sub-Saharan Africas</h1>
       <a href="https://www.researchgate.net/publication/347237168_Telemedicine_and_its_impact_on_breast_cancer_survival_in_Sub-Saharan_Africa?utm_source=twitter&rgutm_meta1=eHNsLUxPdFhzeUhteGRZdUJYRlZ6cUFYN0kvZllkS2VmcC9nbWhDNHZTYlR3WlpLUFFURExEZUJnZmtJWFN1M2praUZrSlQ2ekJwOUpDdUJueGxFa1lSRjEvZz0%3D"  target="_blank" rel="noreferrer noopener"> <i>Curled from my research paper </i></a>
      <img src="img/blog/telemedicine.png" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        Breast cancer is estimated to be the commonest cancer among women worldwide and forms a major part of global disease burden. Whilst the incidence of breast cancer is relatively low in SSA, its survival rates are however poorer compared to high income-countries. Findings from the study revealed that widespread late-stage diagnosis is a consistent pattern seen in SSA, explaining why the mortality rate is high and that through technology, patients can now receive quick, effective and advanced service from healthcare professionals, even remotely.
        </p>
        <p>
        Telemedicine adopts electronic and information technology in the delivery of care and clinical services by health care professionals to populations in different locations.  In many countries, access to telemedicine services have continued to increase and expand with increasing portability reduced costs, improved usability and greater quality.
Many studies have shown at least an equal comparison between in-person care and the action of health care professionals and patients [1,2]. Some other studies show improved outcomes with telemedicine when compared to in-person care 

        </p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          A study from Massachusetts General Hospital reveals that patients reported that their Telemedicine visits and consultations were more engaging and fulfilling than in-person visits. Also, 62 percent of patients equate the quality of Telemedicine visits to in-person visits; 21 percent rated their Telemedicine visit experience higher 
          </p>
        </div>
        <p>
        As stated by Schwamm [8], “Telehealth gives them more of what they want most and gets rid of the stuff they don’t want. With a telehealth visit, 95 percent of the time spent by the patient is face-to-face with the doctor, compared to less than 20 percent of a traditional visit, in which most time is spent traveling and waiting. This findings confirm that “what patients value most is uninterrupted time with their doctor.”
There, therefore, is ample opportunity to explore the impact of telemedicine on the diagnosis and management of chronic diseases like breast cancer which forms a major part of the global disease burden.
While the incidence of breast cancer remains the highest in high-income countries, the incidence continues to rise in low-income and middle-income nations including countries in sub-Saharan Africa (SSA) [2]. In 2018, breast cancer was the commonest cancer diagnosis made in women globally [6]. The survival rates for breast cancer are however poorer in low and middle-income countries when compared to high income-countries [19]. In 2012 for example, low and middle-income countries accounted for 53% of the newly diagnosed cases of breast cancer globally and around 58% of the deaths (IARC., 2012). This negative trend has been predicted to continue in the coming decades owing to the adoption of the lifestyles of HICs and an ageing population [2].

        </p>
        <p>
        SSA is faced with many problems, some of which include high poverty levels, poor infrastructure, acute shortage of healthcare professionals, lack of political will from leaders and disproportionately high burden of disease [46]. These problems are even more emphasized considering Africa’s rising population which has been projected to double in another 40 years [44]. The number of doctors per 100,000 people already deficient in most sub-Saharan African countries, will become even less with the projected population growth [47].
In SSA, there are myriads of barriers affecting access to oncology care. At the healthcare level, effective and appropriate oncology treatments are sparse, waiting times for chemotherapy are long and there is acute scarcity of facilities for radiotherapy [42]. 
At the individual level, fear of treatment’s side effects, fear of disfiguration and fear of stigmatization have all been well documented [31, 15]. This fear stems from belief that death or mastectomy is the aftermath of breast cancer diagnosis. This is the same for other types of cancer. The mention of word cancer itself generates fear with the perception that cancer is a life-threatening, incurable disease [48], not only in SSA but all over the world thereby evoking myriad of negative emotions such as depression and anxiety among people [48]. 

        </p>

        <p>
        In addition, spiritual and traditional healers continue to play a role in patients’ receipt of conventional cancer care [28]. Most women in sub-Saharan African countries, especially those in the rural areas, believe that the traditional method of treating diseases is more efficacious than modern method. This belief in indigenous health system is one of the causes of late presentation and treatment of breast cancer cases in SSA.
There are also some between-setting differences in access to cancer treatments across SSA. In the prospective study by Foerster [15], it was found that while almost all women attending a specialized care centre in Namibia got treated irrespective of their socioeconomic status and race, one in three patients in two regional Nigerian hospitals and one in six patients in Uganda’s main referral hospital had not commenced any form of treatment 1 year following their diagnosis. Surgery and/or chemotherapy are the major form of treatments, but also included radiotherapy for 67% of women at the Namibian Cancer Care Centre, it was discovered that there was a period when radiotherapy machine was not in operation for about one and a half year thereby and only 15% of women in Uganda were treated during this period.
According to Foerster [15], age also plays an important role in cancer treatments across SSA, it was observed that percentages of those receiving treatment are highest among 50–59year-old women. Those aged 70 and over, of which 6% have breast cancer, were least likely to be treated.
 This therefore underlines the importance of making available population-wide affordable and accessible cancer care options in SSA.
Another problem with access to healthcare in SSA is the shortage of specialists especially in rural areas as most of them are found in large cities resulting in geographic inequalities in access to care [27].

        </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalFiveBlogContent;
