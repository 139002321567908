import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DirectionReveal from 'direction-reveal';
import ModalOneContent from './modal/ModalOneContent';
import ModalTwoContent from './modal/ModalTwoContent';
import ModalThreeContent from './modal/ModalThreeContent';
import ModalFourContent from './modal/ModalFourContent';
import ModalFiveContent from './modal/ModalFiveContent';
import ModalSixContent from './modal/ModalSixContent';
import ModalSevenContent from './modal/ModalSevenContent';
import ModalEightContent from './modal/ModalEightContent';
import ModalNineContent from './modal/ModalNineContent';
import ModalTenContent from './modal/ModalTenContent';
import ModalElevenContent from './modal/ModalElevenContent';
import ModalTwelveContent from './modal/ModalTwelveContent';
import ModalThirteenContent from './modal/ModalThirteenContent';
import ModalFourteenContent from './modal/ModalFourteenContent';
import ModalSixteenContent from './modal/ModalSixteenContent';

const PortfolioLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
   const [isOpen16, setIsOpen16] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }
  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }
  function toggleModalTen() {
    setIsOpen10(!isOpen10);
  }
  function toggleModalEleven() {
    setIsOpen11(!isOpen11);
  }
  function toggleModalTwelve() {
    setIsOpen12(!isOpen12);
  }
  function toggleModalThirteen() {
    setIsOpen13(!isOpen13);
  }
  function toggleModalFourteen() {
    setIsOpen14(!isOpen14);
  }

  function toggleModalSixteen() {
    setIsOpen16(!isOpen16);
  }


  useEffect(() => {
    // Init with default setup
    DirectionReveal();

    // Init with all options at default setting
    // DirectionReveal({
    //   selector: ".direction-reveal",
    //   itemSelector: ".direction-reveal__card",
    //   animationName: "slide",
    //   animationPostfixEnter: "enter",
    //   animationPostfixLeave: "leave",
    //   touchThreshold: 500,
    // });
  }, []);

  return (
    <div className="portfolio-tab-content">
      <ul className="row grid">
        {/* <!-- --------------------------- ------------------------------ --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li className="direction-reveal">
          <figure
            className="direction-reveal__card"
            onClick={toggleModalFourteen}
          >
            <img src="img/projects/mytv1.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Market Ready Netflix Clone
              </span>
            </div>
          </figure>

          {/* Start ModalTwoContent */}
          <Modal
            isOpen={isOpen14}
            onRequestClose={toggleModalFourteen}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalFourteen}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalFourteenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalTwoContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- --------------------------- ------------------------------ --> */}

                      {/* <!-- Portfolio Item Starts --> */}

               <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalSixteen}>
            <img src="img/projects/diabetic.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Diabetic Retinopathy Analyzer
              </span>
            </div>
          </figure>

          {/* Start ModalFourContent */}
          <Modal
            isOpen={isOpen16}
            onRequestClose={toggleModalSixteen}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalSixteen}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalSixteenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalFourContent */}
        </li>
            {/* <!-- Portfolio Item Ends --> */}


        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalSix}>
            <img src="img/projects/fashion4.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                IFashion Store Mobile App iOS & Android
              </span>
            </div>
          </figure>

          {/* Start ModalSixContent */}
          <Modal
            isOpen={isOpen6}
            onRequestClose={toggleModalSix}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalSix}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalSixContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalSixContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}

        <li className="direction-reveal">
          <figure className="direction-reveal__card" onClick={toggleModalTen}>
            <img src="img/projects/covid.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                {' '}
                Covid, Pneumonia & Tuberculosis detection using X-Ray
              </span>
            </div>
          </figure>

          {/* Start ModalTwoContent */}
          <Modal
            isOpen={isOpen10}
            onRequestClose={toggleModalTen}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalTen}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalTenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModaTenContent */}
        </li>

        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- --------------------------- ------------------------------ --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li className="direction-reveal">
          <figure className="direction-reveal__card" onClick={toggleModalThree}>
            <img src="img/projects/trivoh.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Video Conferencing App
              </span>
            </div>
          </figure>

          {/* Start ModalThreeContent */}
          <Modal
            isOpen={isOpen3}
            onRequestClose={toggleModalThree}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalThree}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalThreeContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalThreeContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        

            {/* <!-- --------------------------- ------------------------------ --> */}



        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalFour}>
            <img src="img/projects/plas1.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Plasmate Artisan App
              </span>
            </div>
          </figure>

          {/* Start ModalFourContent */}
          <Modal
            isOpen={isOpen4}
            onRequestClose={toggleModalFour}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalFour}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalFourContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalFourContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure
            className="direction-reveal__card"
            onClick={toggleModalThirteen}
          >
            <img src="img/projects/nico.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Nicosoft Media Website
              </span>
            </div>
          </figure>

          {/* Start ModalFiveContent */}
          <Modal
            isOpen={isOpen13}
            onRequestClose={toggleModalThirteen}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalThirteen}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalThirteenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalThirteenContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        
        {/* <!-- Portfolio Item Starts --> */}
        <li className="direction-reveal">
          <figure className="direction-reveal__card" onClick={toggleModalTwo}>
            <img src="img/projects/news1.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">AI NEWS ASSISTANT</span>
            </div>
          </figure>

          {/* Start ModalTwoContent */}
          <Modal
            isOpen={isOpen2}
            onRequestClose={toggleModalTwo}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalTwo}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalTwoContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalTwoContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalFive}>
            <img src="img/projects/cartoon1.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                AI Image Cartoonify Project
              </span>
            </div>
          </figure>

          {/* Start ModalFiveContent */}
          <Modal
            isOpen={isOpen5}
            onRequestClose={toggleModalFive}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalFive}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalFiveContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalFiveContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalSeven}>
            <img src="img/projects/calculator1.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                Desktop Calculator for Mac & Windows
              </span>
            </div>
          </figure>

          {/* Start ModalSevenContent */}
          <Modal
            isOpen={isOpen7}
            onRequestClose={toggleModalSeven}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalSeven}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalSevenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalSevenContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalEight}>
            <img src="img/projects/cod.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">COD Cambridge App</span>
            </div>
            {/* Start ModalEightContent */}
            <Modal
              isOpen={isOpen8}
              onRequestClose={toggleModalEight}
              contentLabel="My dialog"
              className="custom-modal dark green"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalEight}>
                  <img src="/img/cancel.svg" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalEightContent />
                </div>
              </div>
            </Modal>
            {/* End  ModalEightContent */}
          </figure>
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalNine}>
            <img src="img/projects/para.png" alt="Portolio" />
            <div className="direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                NLP Rephrasing Tool
              </span>
            </div>
          </figure>

          {/* Start ModalNineContent */}
          <Modal
            isOpen={isOpen9}
            onRequestClose={toggleModalNine}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalNine}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalNineContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalNineContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- --------------------------- ------------------------------ --> */}

        {/* <!-- Portfolio Item Starts --> */}

        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure
            className="direction-reveal__card"
            onClick={toggleModalEleven}
          >
            <img src="img/projects/hbcu.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title"> HBCU DIGITAL APP</span>
            </div>
          </figure>

          {/* Start ModalTwoContent */}
          <Modal
            isOpen={isOpen11}
            onRequestClose={toggleModalEleven}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalEleven}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalElevenContent />
              </div>
            </div>
          </Modal>
          {/* End  ModaTenContent */}
        </li>

        {/* <!-- --------------------------- ------------------------------ --> */}

                {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure className="direction-reveal__card" onClick={toggleModalOne}>
            <img src="img/projects/mood1.png" alt="Portolio" />
            <div className=" direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">Mood Recognition</span>
            </div>
          </figure>

          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalOne}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalOne}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalOneContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalOneContent */}
        </li>
        {/* <!-- Portfolio Item Ends --> */}

        {/* <!-- --------------------------- ------------------------------ --> */}

        {/* <!-- Portfolio Item Starts --> */}
        <li
          className="direction-reveal"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <figure
            className="direction-reveal__card"
            onClick={toggleModalTwelve}
          >
            <img src="img/projects/bio.png" alt="Portolio" />
            <div className="  direction-reveal__overlay direction-reveal__anim--enter">
              <span className="direction-reveal__title">
                {' '}
                BioPython Protein Analysis
              </span>
            </div>
          </figure>

          {/* Start ModalNineContent */}
          <Modal
            isOpen={isOpen12}
            onRequestClose={toggleModalTwelve}
            contentLabel="My dialog"
            className="custom-modal dark green"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div>
              <button className="close-modal" onClick={toggleModalTwelve}>
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner portfolio">
                <ModalTwelveContent />
              </div>
            </div>
          </Modal>
          {/* End  ModalTwelveContent */}
        </li>

        {/* <!-- Portfolio Item Ends --> */}
      </ul>
    </div>
  );
};

export default PortfolioLight;
