import React, { useState } from 'react';
import Modal from 'react-modal';
import Index from '../about/index';
import Textra from 'react-textra';

const heroContent = {
  heroImage: 'img/hero/pic2.jpg',
  heroMobileImage: 'img-mobile',
  heroTitleName: 'Johnson Oyeniyi',
  heroDesignation: [
    'Front End Developer',
    'Mobile App Developer',
    'Full Stack Engineer',
    'ML Engineer',
  ],
  heroDescriptions: `An analytical and motivated Front-end, mobile application developer and ML engineer, with knowledge of agile work environment and 10+ years of hands-on experience in developing database applications and integrating AI modules into those applications. Able to execute outstanding data extraction and analysis through using algorithmic and data mining techniques, with an appealing frontend user interface to inform and advise on business development and workflow improvement. Adept at synthesizing complex data into actionable reporting, and proposals; and driving quantifiable business growth and profit for reputable companies in different sectors.`,
  heroBtn: 'more about me',
};

const HeroLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={`img/hero/${heroContent.heroMobileImage}.jpg`}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              I'm {heroContent.heroTitleName}.
              <span>
                {' '}
                <Textra effect="flash" data={heroContent.heroDesignation} />
              </span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
            <button className="button" onClick={toggleModalOne}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
      {/* End home-details-container */}

      {/* Start Modal for About More */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal dark light"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner about green">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  ABOUT <span>ME</span>
                </h1>
                <span className="title-bg">My CV</span>
              </div>
              {/* End title */}
              <Index />
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for About More */}
    </>
  );
};

export default HeroLight;
