import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Johnson" },
  { meta: "last name", metaInfo: "Oyeniyi" },
  { meta: "phone", metaInfo: "+44 7754 327291" },
  { meta: "Location", metaInfo: "London, UK" },
  { meta: "Email", metaInfo: "netappzone@gmail.com" },
  { meta: "linkedin", metaInfo: "https://www.linkedin.com/in/\njohnsonoy/" },
  
];



const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
             {/* {val.metaInfo} */}
             {val.meta === "linkedin" ? <a href={val.metaInfo} target="_blank" rel="noreferrer noopener"><i>{val.metaInfo}</i></a> : <i>{val.metaInfo}</i> }
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
