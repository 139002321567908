import React from "react";
import Slider from "react-slick";

const ModalSevenContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Desktop Calculator App</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Project </span>:{" "}
              <span className="ft-wt-600 uppercase">Desktop Calculator for Mac & Windows</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Client </span>:{" "}
              <span className="ft-wt-600 uppercase">Personal Project</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Languages </span>:{" "}
              <span className="ft-wt-600 uppercase">Flutter & Dart, Hive</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Preview </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.apple.com/uk/app-store/"
                  target="_blank"
                  rel="noreferrer"
                >
                 App Store
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <Slider {...settings}>
          <div className="slide_item">
          <img src="img/projects/calculator1.png" alt="Portolio" />
          </div>
          <div className="slide_item">
          <img src="img/projects/calculator.png" alt="Portolio" />
          </div>
          <div className="slide_item">
          <img src="img/projects/calculator2.png" alt="Portolio" />
          </div>
        </Slider>
        
        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow 
  );
};

export default ModalSevenContent;
