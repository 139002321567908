import React from "react";

const ModalSixBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Blog <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O.
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 02 August 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> covid, pandemi, remote working, programming
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>Pandemic Programming</h1>
      <img src="img/blog/pan.jpg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        Covid-19 came and changed the world's order, from the way we behave to the way we dress. New social norms emerged overnight: an elbow bump rapidly replaced a handshake, and your bedroom became your workspace.
             
        </p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          With only a proportion of developers classified as key workers (where their responsibilities perhaps included the operations-side of keeping mission-critical and life-critical systems up and online), the majority of programmers will have been forced to work remotely, often in solitude.
          </p>
        </div>
       
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalSixBlogContent;
