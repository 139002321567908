import React from "react";

const ModalOneBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Blog <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Johnson O.
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 25 March 2022
        </span>
        <span>
          <i className="fa fa-tags"></i> docker, container, kubernetes
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>Docker: Explained in layman's language. 👶🏻</h1>
      <img src="img/blog/docker.jpeg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        Docker is a tool that allows you to containerize apps i.e putting apps in boxes of their own. It creates a virtual computer magically, although they're not actually virtual.
        </p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          Docker is a popular virtualization tool that replicates a specific operating environment on top of a host OS. It is used by development teams to ensure consistency across different machines.
          It is a software that offers a set of platform-as-a-service products for developing and deploying applications by packaging software in containers.
          </p>
        </div>
        <p>
        Containers are boxes that run independently of the device they're on since they don't have a host operating system.
        Consider this scenario: you know that a bee likes to dwell in its own honeycomb and 
        will be unable to function effectively if it is forced to reside elsewhere. You captured a bee and because of your prior knowledge of bee keeping, you put the captured bee in a box that resembles 
        honeycomb in appearance and feel. That's what  containerization is all about.
        </p>
        <p>
        Docker Images are similar to templates - a craftbook that contains everything needed to complete the craft. 
        Or, to put it another way, it includes a collection of instructions for 
        building a container. But how do you create these images (which will be 
        used to create containers later)? Dockerfiles are used to do this.</p>
        <p>A Dockerfile is a text file that contains all of the instructions that a 
          user may use to build an image. Let's create a Dockerfile together. Now, 
          let's get docker HANDS ON! 
          Download Docker on your device quickly:  <a href="https://www.docker.com/get-started" target="_blank" rel="noreferrer noopener">"https://www.docker.com/get-started"</a></p>
        <p>  Now that you've got everything, let's construct a small flask app and containerize it! 
          Here's a really basic and minimalist flask app.</p>
          <img src="img/blog/doc1.png" className="img-fluid" alt="Blog" />

          <p>
          Even though it appears to be simple, it requires a number of components to function properly:</p>

<li>Python 3.9</li>
<li>Flask (running pip install flask)</li>
<li>Port 5000</li>
<br></br>

<p>
All of these issues are resolved by creating a simple dockerfile that creates a docker image for us. With this dockerfile, you are not restricted to the limitation of your operating system.
As a result, you must create a Dockerfile file (exactly, without any file extension)</p>

<p>
Here's a step-by-step guide:</p>


<li>Use FROM to load the Python base image,</li> 
<li>Use COPY to copy the app.py file into the container,</li> 
<li>Use RUN to  pipinstall Flask</li>
<li>When the container runs, use CMD to execute "python app.py."</li>
<br></br>
<p>
  It's as simple as that!!!
          </p>
          <img src="img/blog/doc2.png" className="img-fluid" alt="Blog" />
          <p>
          Now, use <i>docker build</i> command to build the docker image and then <i>docker build</i> command to run the image. You can also use the <i>--tag</i> keyword to give a name to the image and make it easier for yourself to run later. just like the syntax below</p>
<li>docker build --tag flask</li>
<li>docker run --name flask -p 5000:5000 flask</li>
<p>
Note that, <i>--name</i> is the name of the container to be run (which I call flask), <i>-p</i> sets the port of the docker CONTAINER to your machine, so you can see your app on localhost. You can use the <i>docker ps</i>command to get a list of running containers,
<i>docker ps -a</i> to get list of all containers and <i>docker images</i> to get list of images, while <i>docker --help</i> will get the list of all commands
          </p>



      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalOneBlogContent;
